/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";


$accent: #506096;
$text-color: #2e2e2e;
$font-variant: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;


@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en");
@import '@abt-desk/rsc/lib/styles.css';
@import '~@abt-desk/realogy-mat-theme/src/theme';
@include angular-material-theme($theme);

body {
  font-family: $font-variant !important;
  font-size: 14px;
  margin: 0;
}

a:hover {
  cursor: pointer;
}

.wrapper {
  margin: 20px 10px 10px;
}

@media print{
  body{ 
        margin-top: 10mm; margin-bottom: 20mm; 
        margin-left: 0mm; margin-right: 0mm;
        border: 1px solid gray; padding:0;float: none;position: static;     overflow: visible;
        
             }
             [ion-content-print]{ overflow-y: visible !important; position: relative; }
}
 @page {
  margin-top: 3mm;
  margin-bottom: 0.4mm;
}

.contentPage{
    margin: 5px;
    height: 100%;
    text-align: center;
}

input, select, textarea, optgroup, option, h1, h2, h3, h4, h5, h6 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #020249;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--rsc-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--rsc-primary);
}

.align-hrz {
  display: flex; 
  flex-direction: row; 
  gap: 15px; 
  align-items: center;
}

.stretch-hrz {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 10px;
}

.error-msg {
  color: #D32F2F;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: flex-start;
  gap: 2px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.error-msg > div {
  max-width: 260px;
}

/* You can add global styles to this file, and also import other style files */
.mat-expansion-panel {
  border-radius: 0px !important;
  }

  .mat-drawer-content {
    position: fixed !important;
    }

  .active-url{
    background-color:  var(--rsc-primary) !important;
  }

  .active-url label{
    color:  #fff !important;
  }
  .active-url i{
    filter: brightness(0) invert(1);
  }

  .oIcon {
    background: url(../src/assets/icons/O.svg) no-repeat top left;
    background-size: cover;
  }

  .uIcon {
    background: url(../src/assets/icons/U.svg) no-repeat top left;
    background-size: cover;
  }

  .sIcon {
    background: url(../src/assets/icons/S.svg) no-repeat top left;
    background-size: cover;
  }

  .greenCheck {
    background: url(../src/assets/icons/CheckCircleOutlined.svg) no-repeat top left;
    background-size: cover;
  }

  .navHome {
    background: url(../src/assets/icons/house.svg) no-repeat top left;
    height: 23px;
  }

  .navProspect {
    background: url(../src/assets/icons/MyProspects.svg) no-repeat top left;
    height: 23px;
  }

  .navAgent {
    background: url(../src/assets/icons/MyAgents.svg) no-repeat top left;
    height: 23px;
  }

  .navSearch {
    background: url(../src/assets/icons/SearchFilled.svg) no-repeat top left;
    height: 23px;
  }

  .navReport {
    background: url(../src/assets/icons/Reports.svg) no-repeat top left;
    height: 23px;
  }

  .navArrow{
    background: url(../src/assets/icons/Expand.svg) no-repeat top left;
    height: 23px;
    transform: translateX(0) rotate(0deg);
    margin-right: 47px !important;
  }

  .expansion-icon{
    border: none !important;
    left:16px !important;
    background: transparent !important;
    margin-top: 0px !important;
  }

  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: transparent !important;
}

  .rsc-side-nav, .sidenav-drawer{
    padding: 0px !important;
    //transform: translateX(100px) !important;
  }

  // .sidenav-drawer{
  //   transform: translateX(100px) !important;
  // }

  // .sidenav-drawer div div{
  //   transform: translateX(calc(160px)) !important;
  // }

  // .mat-drawer-content{
  //   transform: translateX(100px) !important;
  //   width: calc(100% - 100px) !important;
  // }
  // .rsc-side-nav, sidenav-drawer{
  //   padding: 0px !important;
  // }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 12px;
    align-items: baseline;
  }

  .content {
    margin: 25px;
    height: 100%;
  }

  .fa-arrow-right-from-line:before{
    content: "\f324" !important;
    font-weight: bolder;
    font-size: 18px !important;
  }

  .accordion-container{
    margin-top: 26px !important;
    height: 92% !important;
  }